<template>
  <base-wrapper :loading="loading">
    <datatable
      :columns="tableColumns"
      :total="orderDetails.meta.page.total"
      :perPage="orderDetails.meta.page.perPage"
      :currentPage="orderDetails.meta.page.currentPage"
      @pagechanged="onChangePage"
    >
      <template #tbody="{ classes }">
        <tr
          v-for="orderDetail in orderDetails.data"
          :key="orderDetail.id"
          :class="[classes.tr, 'bg-white']"
        >
          <td :class="classes.td">
            {{
              getSingleIncluded(
                orderDetails,
                orderDetail.relationships.order.data.id
              ).attributes.origin_code
            }}
            /
            {{
              getSingleIncluded(
                orderDetails,
                orderDetail.relationships.order.data.id
              ).attributes.destination_code
            }}
          </td>
          <td :class="classes.td">
            {{
              getSingleIncluded(
                orderDetails,
                orderDetail.relationships.order.data.id
              ).attributes.origin_office_code
            }}
          </td>
          <td :class="classes.td">{{ orderDetail.attributes.qr_qty }}</td>
          <td :class="classes.td">
            {{ orderDetail.attributes.qr_start }} -
            {{ orderDetail.attributes.qr_end }}
          </td>
        </tr>
      </template>
    </datatable>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  data() {
    return {
      orderDetails: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'order_code', name: 'Kode PO' },
        { id: 'origin_code', name: 'Kode Supplier' },
        { id: 'qr_qty', name: 'Jumlah QR' },
        { id: 'qr_range', name: 'Range QR' },
      ];
    },
  },
  methods: {
    async loadOrderDetails(params) {
      this.loading = true;

      const [res, err] = await this.request(`/api/v1/order-details`, {
        params: {
          'filter[package_method]': 'unique_qrcode',
          include: 'order',
          'fields[order-details]': 'qr_start,qr_end,qr_qty,order',
          'fields[orders]': 'origin_code,origin_office_code,destination_code',
          'page[size]': 5,
          ...params,
        },
      });

      if (!err) {
        this.orderDetails = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadOrderDetails({
        'page[number]': page,
      });
    },
  },
  created() {
    this.loadOrderDetails();
  },
};
</script>
