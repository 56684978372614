<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input
          type="search"
          placeholder="Cari Supplier"
          :shadow="false"
          debounce
          v-model="filter.supplier_code"
          @native-input="loadProductQrCodes"
        />
        <base-input
          type="search"
          placeholder="Cari Kode"
          :shadow="false"
          debounce
          v-model="filter.qrcode"
          @native-input="loadProductQrCodes"
        />
        <base-input
          type="search"
          placeholder="Cari Kode Produk"
          :shadow="false"
          debounce
          v-model="filter.product_code"
          @native-input="loadProductQrCodes"
        />
        <product-qr-code-status-select
          v-model="filter.status"
          @change="loadProductQrCodes"
        />
      </div>
      <datatable
        :columns="tableColumns"
        :total="productQrCodes.total"
        :perPage="productQrCodes.per_page"
        :currentPage="productQrCodes.current_page"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="item in productQrCodes.data"
            :key="item.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onDetail(item)"
          >
            <td :class="classes.td">
              <qr-code :text="item.qrcode" :size="100" />
            </td>
            <td :class="classes.td">
              {{ item.product ? item.product.code : '-' }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <product-qr-code-badge :product-qr-code="item" />
            </td>
            <td :class="classes.td">
              {{ item.supplier ? item.supplier.code : '-' }}
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <product-qr-code-detail-modal
      :visible="detailModal.visible"
      :product-qr-code-id="detailModal.id"
      @close="detailModal.visible = false"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import QrCode from 'vue-qrcode-component';
import ProductQrCodeBadge from '@/components/product-qr-code/product-qr-code-badge.vue';
import ProductQrCodeStatusSelect from '@/components/product-qr-code/product-qr-code-select.vue';
import { mapActions } from 'vuex';
import ProductQrCodeDetailModal from '@/components/product-qr-code/product-qr-code-detail-modal.vue';

export default {
  mixins: [requestMixin],
  components: {
    QrCode,
    ProductQrCodeBadge,
    ProductQrCodeStatusSelect,
    ProductQrCodeDetailModal,
  },
  data() {
    return {
      detailModal: {
        id: null,
        visible: false,
      },
      filter: {
        supplier_code: null,
        qrcode: null,
        product_code: null,
        status: null,
      },
      loading: false,
      productQrCodes: {
        data: [],
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'qrcode', name: 'QR Code' },
        { id: 'product', name: 'Kode Barang' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
        { id: 'supplier', name: 'Kode Supplier' },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadProductQrCodes(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/generate-qr-code-histories/${this.$route.params.id}/-actions/product-qr-codes`,
        {
          params: {
            supplier_code: this.filter.supplier_code || null,
            qrcode: this.filter.qrcode || null,
            product_code: this.filter.product_code || null,
            ...this.filter.status,
            ...params,
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
          timeout: 1000,
        });

        this.productQrCodes = {
          data: [],
        };
      } else {
        this.productQrCodes = res;
      }

      this.loading = false;
    },
    onDetail(item) {
      this.detailModal.id = item.uuid;
      this.detailModal.visible = true;
    },
    onChangePage(page) {
      this.loadProductQrCodes({
        page,
      });
    },
  },
  created() {
    this.loadProductQrCodes();
  },
};
</script>
