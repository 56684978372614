<template>
  <div>
    <base-modal
      :showing="visible"
      @close="$emit('close')"
      size="max-w-5xl"
      @opened="onOpened"
    >
      <base-card
        title="Produk Box QR"
        description="Daftar produk box qr"
        :with-style="false"
      >
        <div class="space-y-4 border-t py-5">
          <div class="flex justify-end">
            <base-button @click="onCreateBox">
              <Icon icon="heroicons:plus" class="h-4 w-4" />
              Tambah Box
            </base-button>
          </div>
          <datatable
            :columns="tableColumns"
            :total="productBoxes.meta.page.total"
            :perPage="productBoxes.meta.page.perPage"
            :currentPage="productBoxes.meta.page.currentPage"
            @pagechanged="onChangePage"
          >
            <template #tbody="{ classes }">
              <tr
                v-for="(product, index) in productBoxes.data"
                :key="product.id"
                :class="classes.tr"
              >
                <td :class="classes.td">
                  <qr-code :text="product.attributes.qrcode" :size="50" />
                </td>
                <td :class="classes.td">{{ product.attributes.name }}</td>
                <td :class="classes.td">
                  {{ product.attributes.product_count | toCurrency }}
                </td>
                <td :class="classes.td">
                  <base-toggle
                    with-label
                    label-true="Aktif"
                    label-false="Tidak Aktif"
                    v-model="productBoxes.data[index].attributes.is_active"
                    @change-debounce="onUpdateBoxStatus(product)"
                  />
                </td>
                <td :class="[classes.td, 'text-right']">
                  <button @click="onDelete(product.id)">
                    <Icon icon="heroicons:trash-solid" class="w-4 h-4 text-red-600" />
                  </button>
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </base-card>
    </base-modal>

    <base-qr-scan-modal
      :with-camera-scan="false"
      :visible="visibleFormModal"
      @close="visibleFormModal = false"
    >
      <template #bottom>
        <form class="space-y-4" @submit.prevent="onSubmitForm">
          <base-input
            inset
            type="text"
            label="Nama Box"
            placeholder="Nama Box"
            required
          >
            <div class="flex mt-1">
              <p class="text-sm text-gray-500">{{ productCode }}-</p>
              <input type="text" class="w-full p-0 border-0 text-sm focus:ring-0" v-model="form.name">
            </div>
          </base-input>
          <base-input
            inset
            type="text"
            label="Jumlah Barang"
            placeholder="Jumlah Barang"
            required
            v-model="form.qty"
          />
          <base-button fullwidth>Simpan</base-button>
        </form>
      </template>
    </base-qr-scan-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseToggle from '@/components/base/BaseToggle.vue';
import QrCode from 'vue-qrcode-component';
import BaseQrScanModal from '@/components/base/BaseQrScanModal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    QrCode,
    BaseQrScanModal,
    BaseToggle,
  },
  props: {
    visible: Boolean,
    productId: String,
    productCode: String,
  },
  emits: ['close', 'created'],
  data() {
    return {
      visibleFormModal: false,
      form: {
        name: null,
        qty: null,
      },
      loadingData: false,
      loadingSubmit: false,
      loadingUpdateStatus: false,
      loaded: false,
      productBoxes: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    loading() {
      return this.loadingData || this.loadingSubmit || this.loadingUpdateStatus;
    },
    tableColumns() {
      return [
        { id: 'qr', name: 'QR' },
        { id: 'name', name: 'Nama' },
        { id: 'qty', name: 'Jumlah Barang' },
        { id: 'status', name: 'Status' },
        { id: 'action', name: 'Aksi', theadClass: 'text-right' },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadProductBoxes(params) {
      this.loadingData = true;

      const [res, err] = await this.request(
        `/api/v1/products/${this.productId}/product-boxes`,
        {
          params: {
            'fields[products]': 'qrcode,name,product_count,is_active',
            'page[size]': 5,
            sort: '-is_active,-product_count',
            ...params,
          },
        }
      );

      if (!err) {
        this.productBoxes = res;

        this.loaded = true;
      }

      this.loadingData = false;
    },
    onChangePage(page) {
      this.loadProductBoxes({
        'page[number]': page,
      });
    },
    onOpened() {
      this.loadProductBoxes();
    },
    async onSubmitForm() {
      this.loadingSubmit = true;

      const code = `${this.productCode}-${this.form.name}`.replace(/[^a-zA-Z0-9]/gi, '');
      const qty = Number(this.form.qty);

      const [, err] = await this.request(`/api/v1/products`, {
        method: 'post',
        data: {
          data: {
            type: 'products',
            attributes: {
              code,
              name: this.form.name,
              qrcode: `${code}-${qty}`,
              product_count: qty,
            },
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: this.productId,
                },
              },
            },
          },
        },
      });

      if (!err) {
        this.loadProductBoxes();

        this.visibleFormModal = false;

        this.$emit('created')
      } else {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      }

      this.loadingSubmit = false;
    },
    async onDelete(productId) {
      this.loadingSubmit = true;

      const [, err] = await this.request(`/api/v1/products/${productId}`, {
        method: 'delete'
      });

      if (!err) {
        this.loadProductBoxes();

        this.$emit('created')
      } else {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      }

      this.loadingSubmit = false;
    },
    onCreateBox() {
      this.form.name = null;
      this.form.qty = null;

      this.visibleFormModal = true;
    },
    async onUpdateBoxStatus(box) {
      this.loadingUpdateStatus = true;

      await this.request(`/api/v1/products/${box.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'products',
            id: box.id,
            attributes: {
              is_active: box.attributes.is_active,
            },
          },
        },
      });

      this.loadingUpdateStatus = false;
    },
  },
};
</script>
