<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <base-card title="Buat Marketing Whatsapp">
        <form class="space-y-4" @submit.prevent="onProcess">
          <base-input
            label="Pilih File"
            fullwidth
            :shadow="false"
            type="file"
            accept=".csv"
            size="padding-md"
            required
            @change="onChangeFile"
          />
          <base-button type="submit" :loading="loadingProcess.visible">{{
            loadingProcess.visible ? loadingProcessLabel : 'Proses'
          }}</base-button>
        </form>
      </base-card>

      <base-card title="Log Error">
        <datatable
          :columns="[
            { id: 'date', name: 'Tanggal' },
            { id: 'message', name: 'Keterangan' },
          ]"
          :total="errorLogs.meta.page.total"
          :perPage="errorLogs.meta.page.perPage"
          :currentPage="errorLogs.meta.page.currentPage"
          @pagechanged="onChangePageErrorLogs"
        >
          <template #tbody="{ classes }">
            <tr v-for="log in errorLogs.data" :key="log.id" :class="classes.tr">
              <td :class="classes.td">
                {{ log.attributes.datetime | formatDate }}
              </td>
              <td :class="classes.td">{{ log.attributes.message }}</td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters, mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      channelName: null,
      errorLogs: {
        data: [],
        meta: {
          page: {},
        },
      },
      file: null,
      loadingErrorLog: false,
      loadingPage: false,
      loadingProcess: {
        visible: false,
        text: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    loading() {
      return this.loadingPage || this.loadingErrorLog;
    },
    loadingProcessLabel() {
      return {
        wait: 'Mengirim Data',
        queued: 'Menunggu Diproses',
        processing: 'Sedang Diproses',
      }[this.loadingProcess.text];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    addListener() {
      this.channelName = `private-App.Models.User.${this.me.id}`;

      const userChannel = this.$pusher.subscribe(this.channelName);

      userChannel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (e) => {
          if (
            e.type ===
            'App\\Notifications\\MarketingMemberWhatsappProcessUpdated'
          ) {
            if (e.status === 'processing') {
              this.startLoadingProcess('processing');
            } else if (e.status === 'failed') {
              this.stopLoadingProcess();

              this.createAlert({
                status: 'error',
                data: e.message,
              });
            } else if (e.status === 'finished') {
              this.loadErrorLogs();
              this.stopLoadingProcess();

              this.createAlert({
                status: 'success',
                data: 'Berhasil membuat marketing whatsapp',
              });
            }
          }
        }
      );
    },
    async loadErrorLogs(params) {
      this.loadingErrorLog = true;

      const [res, err] = await this.request('/api/v1/logs', {
        params: {
          'filter[type]': 'marketing_member_error',
          'fields[logs]': 'datetime,message',
          'page[size]': 5,
          'sort': '-id',
          ...params,
        },
      });

      if (!err) {
        this.errorLogs = res;
      }

      this.loadingErrorLog = false;
    },
    async loadJobStatus() {
      this.loadingPage = true;

      const [res, err] = await this.request(
        '/api/v1/users/-actions/check-job-status',
        {
          params: {
            type: 'marketing_member',
          },
        }
      );

      if (!err) {
        const { running, status } = res;

        if (running) {
          this.startLoadingProcess(status);
        }
      }

      this.loadingPage = false;
    },
    onChangeFile(e) {
      this.file = e.target.files[0];
    },
    async onProcess() {
      this.startLoadingProcess('wait');

      const formData = new FormData();

      formData.append('file', this.file);

      const [, err] = await this.request(
        '/api/v1/offices/-actions/create-marketing-member-whatsapp',
        {
          method: 'post',
          data: formData,
        }
      );

      if (!err) {
        this.startLoadingProcess('queued');
      } else {
        this.stopLoadingProcess();
      }
    },
    onChangePageErrorLogs(page) {
      this.loadErrorLogs({
        'page[number]': page,
      });
    },
    removeListener() {
      this.$pusher.unsubscribe(this.channelName);
    },
    startLoadingProcess(text) {
      this.loadingProcess.text = text;
      this.loadingProcess.visible = true;
    },
    stopLoadingProcess() {
      this.loadingProcess.visible = false;
    },
  },
  created() {
    this.loadJobStatus();
    this.loadErrorLogs();
    this.addListener();
  },
  beforeRouteLeave(to, from, next) {
    this.removeListener();
    next();
  },
};
</script>
