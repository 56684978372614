<template>
  <div>
    <base-select-search
      :is-loading="loading"
      :data="offices.data"
      :shadow="shadow"
      label="code"
      v-model="selected"
      v-on:infinite-scroll="handleInfiniteScroll"
      v-on:search="handleSearch"
      v-on:change="handleChange"
    >
      <template #label="{ item }">
        <span v-if="withName">
          {{ item.attributes.code }} - {{ item.attributes.name }}
        </span>
        <span v-else>
          {{ item.attributes.code }}
        </span>
      </template>
    </base-select-search>
  </div>
</template>

<script>
import BaseSelectSearch from '@/components/base/BaseSelectSearch.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'OfficeSelectSearch',
  mixins: [requestMixin],
  components: { BaseSelectSearch },
  props: {
    value: null,
    limit: {
      type: Number,
      default: 10,
    },
    officeCategoryId: null,
    filter: {
      type: Object,
      default: () => ({}),
    },
    withName: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    requestParams: Object
  },
  emits: ['input', 'change'],
  data: function () {
    return {
      search: null,
      size: null,
      loading: false,
      offices: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    selected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    officeCategoryId: function () {
      this.loadOffices();
    },
  },
  methods: {
    init: function () {
      this.size = this.limit;
      
      this.loadOffices();
    },
    loadOffices: async function () {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/offices', {
        params: {
          'page[limit]': this.size,
          'filter[search]': this.search,
          'fields[offices]': 'code,name',
          ...this.requestParams
        },
      });

      if (!error) {
        this.offices = res;
      }

      this.loading = false;
    },
    handleInfiniteScroll: function () {
      if (this.offices.meta.page.hasMore) {
        this.size += this.limit;

        this.loadOffices();
      }
    },
    handleSearch: function (search) {
      this.size = this.limit;
      this.search = search;

      this.loadOffices();
    },
    handleChange: function () {
      this.$emit('change');
    },
  },
  created: function () {
    this.init();
  },
};
</script>
