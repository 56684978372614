<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input :shadow="false" type="date" v-model="filter.date" @change="loadLogs" />
        <base-select :shadow="false" :expand="false" :options="[
          {
            key: 'all',
            value: null,
            label: 'Status'
          },
          ...Object.entries(badgeText).map(([value, label]) => ({
            key: value,
            value,
            label
          }))
        ]" v-model="filter.status" @change="loadLogs" />
        <base-select :shadow="false" :expand="false" :options="messageTypeOptions" v-model="filter.message_type" @change="loadLogs" />
      </div>
      <datatable
        :total="logs.meta.page.total"
        :perPage="logs.meta.page.perPage"
        :currentPage="logs.meta.page.currentPage"
        :columns="[
          { id: 'date', name: 'Tanggal' },
          { id: 'name', name: 'Nama' },
          { id: 'phone', name: 'No Telp' },
          { id: 'message_type_label', name: 'Tipe' },
          { id: 'status', name: 'Status', theadClass: 'text-center' },
        ]"
        :scroll-x="false"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr v-for="log in logs.data" :key="log.id" :class="[classes.tr, 'bg-white']">
            <td :class="classes.td">{{ log.attributes.createdAt | formatDate }}</td>
            <td :class="classes.td">{{ log.attributes.name ?? '-' }}</td>
            <td :class="classes.td">{{ log.attributes.cellphone }}</td>
            <td :class="classes.td">{{ log.attributes.message_type_label }}</td>
            <td :class="[classes.td, 'text-center']">
              <base-badge :color="badgeColors[log.attributes.status]" :title="log.attributes.recent_failed_message">
                {{ badgeText[log.attributes.status] }}
              </base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import dayjs from 'dayjs';

export default {
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        date: dayjs().format('YYYY-MM-DD'),
        status: null,
        message_type: null
      },
      loadingLogs: false,
      logs: {
        meta: {
          page: {}
        },
        data: []
      }
    }
  },
  computed: {
    badgeColors() {
      return {
        created: 'yellow',
        processed: 'green',
        failed: 'red',
        sent: 'purple',
        read: 'blue',
        delivered: 'indigo',
      }
    },
    badgeText() {
      return {
        created: 'Menuggu Diproses',
        processed: 'Diproses',
        sent: 'Terkirim',
        delivered: 'Diterima',
        read: 'Dibaca',
        failed: 'Gagal',
      }
    },
    loading() {
      return this.loadingLogs
    },
    messageTypeOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Tipe'
        },
        {
          key: 'notify_updated_user',
          value: 'notify_updated_user',
          label: 'Notifikasi Update User'
        },
        {
          key: 'marketing_member',
          value: 'marketing_member',
          label: 'Marketing Member'
        },
      ]
    }
  },
  methods: {
    async loadLogs(params = {}) {
      this.loadingLogs = true

      const [res, err] = await this.request('/api/v1/whatsapp-send-logs', {
        params: {
          'fields[whatsapp-send-logs]': 'createdAt,message_type_label,cellphone,status,recent_failed_message,name',
          'page[size]': 5,
          'filter[date]': this.filter.date,
          'filter[status]': this.filter.status,
          'filter[message_type]': this.filter.message_type,
          'sort': '-id',
          ...params
        }
      })

      if (!err) {
        this.logs = res
      }

      this.loadingLogs = false
    },
    onChangePage(page) {
      this.loadLogs({
        'page[number]': page
      })
    }
  },
  created() {
    this.loadLogs()
  }
}
</script>