<template>
  <div class="relative flex items-center">
    <label>
      <input
        type="checkbox"
        class="peer hidden"
        v-model="checked"
        @change="onChange"
      />
      <div
        class="peer-focus:outline-none peer h-5 w-9 cursor-pointer rounded-full bg-gray-200 transition-all duration-500 ease-in-out after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] hover:bg-gray-300 peer-checked:bg-green-600 peer-checked:after:translate-x-full peer-checked:after:border-white hover:peer-checked:bg-indigo-700 dark:border-gray-600"
      ></div>
    </label>
    <span v-if="withLabel" class="ml-3 text-sm font-medium text-gray-700">{{
      checked ? labelTrue : labelFalse
    }}</span>
  </div>
</template>

<script>
import { debounce } from 'debounce';

export default {
  props: {
    value: null,
    labelTrue: {
      type: String,
      default: 'Checked',
    },
    labelFalse: {
      type: String,
      default: 'Unchecked',
    },
    withLabel: Boolean,
  },
  emits: ['input', 'change', 'change-debounce'],
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onChangeDebounce: debounce(function (value) {
      this.$emit('change-debounce', value);
    }, 500),
    onChange() {
      this.$emit('change');
      this.onChangeDebounce(this.checked);
    },
  },
};
</script>
