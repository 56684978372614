<template>
  <div>
    <base-modal
      size="max-w-4xl"
      :showing="visible"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        title="Detail QR Code"
        :with-style="false"
        description="Log aktifitas QR Code"
      >
        <div class="border-t pt-5">
          <datatable :columns="tableColumns">
            <template #tbody="{ classes }">
              <tr v-for="(log, index) in logs" :key="index" :class="classes.tr">
                <td :class="classes.td">{{ log.timestamp | formatDate }}</td>
                <td :class="classes.td">{{ log.user }}</td>
                <td :class="classes.td">{{ log.status }}</td>
              </tr>
            </template>
          </datatable>
        </div>
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    productQrCodeId: null,
  },
  emits: ['close'],
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      logs: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'timestamp', name: 'Tanggal' },
        { id: 'user', name: 'User' },
        { id: 'status', name: 'Status' },
      ];
    },
  },
  methods: {
    async onOpened() {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/product-qr-codes/${this.productQrCodeId}`,
        {
          params: {
            'fields[product-qr-codes]': 'logs',
          },
        }
      );

      if (!err) {
        this.logs = res.data.attributes.logs;
      }

      this.loading = false;
    },
  },
};
</script>
