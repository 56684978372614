var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-input',{attrs:{"shadow":false,"type":"date"},on:{"change":_vm.loadLogs},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}}),_c('base-select',{attrs:{"shadow":false,"expand":false,"options":[
        {
          key: 'all',
          value: null,
          label: 'Status'
        },
        ...Object.entries(_vm.badgeText).map(([value, label]) => ({
          key: value,
          value,
          label
        }))
      ]},on:{"change":_vm.loadLogs},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('base-select',{attrs:{"shadow":false,"expand":false,"options":_vm.messageTypeOptions},on:{"change":_vm.loadLogs},model:{value:(_vm.filter.message_type),callback:function ($$v) {_vm.$set(_vm.filter, "message_type", $$v)},expression:"filter.message_type"}})],1),_c('datatable',{attrs:{"total":_vm.logs.meta.page.total,"perPage":_vm.logs.meta.page.perPage,"currentPage":_vm.logs.meta.page.currentPage,"columns":[
        { id: 'date', name: 'Tanggal' },
        { id: 'name', name: 'Nama' },
        { id: 'phone', name: 'No Telp' },
        { id: 'message_type_label', name: 'Tipe' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ],"scroll-x":false},on:{"pagechanged":_vm.onChangePage},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.logs.data),function(log){return _c('tr',{key:log.id,class:[classes.tr, 'bg-white']},[_c('td',{class:classes.td},[_vm._v(_vm._s(_vm._f("formatDate")(log.attributes.createdAt)))]),_c('td',{class:classes.td},[_vm._v(_vm._s(log.attributes.name ?? '-'))]),_c('td',{class:classes.td},[_vm._v(_vm._s(log.attributes.cellphone))]),_c('td',{class:classes.td},[_vm._v(_vm._s(log.attributes.message_type_label))]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.badgeColors[log.attributes.status],"title":log.attributes.recent_failed_message}},[_vm._v(" "+_vm._s(_vm.badgeText[log.attributes.status])+" ")])],1)])})}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }