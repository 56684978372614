<template>
  <base-badge :color="color">{{ status }}</base-badge>
</template>

<script>
export default {
  props: {
    productQrCode: Object,
    jsonapi: Boolean,
  },
  computed: {
    item() {
      if (this.jsonapi) {
        return this.productQrCode.attributes;
      }

      return this.productQrCode;
    },
    color() {
      if (this.item.is_used) {
        return 'indigo';
      }

      if (this.item.is_active) {
        return 'green';
      }

      if (this.item.is_scanned) {
        return 'red';
      }

      if (this.item.is_ordered) {
        return 'blue';
      }

      if (this.item.is_packed) {
        return 'purple';
      }

      if (this.item.is_valid) {
        return 'blue';
      }

      if (this.item.is_prepared) {
        return 'yellow';
      }

      return 'yellow';
    },
    status() {
      if (this.item.status) {
        return this.item.status;
      }

      if (this.item.is_used) {
        return 'Digunakan';
      }

      if (this.item.is_active) {
        return 'Aktif';
      }

      if (this.item.is_scanned) {
        return 'Discan Penerimaan';
      }

      if (this.item.is_ordered) {
        return 'Diorder';
      }

      if (this.item.is_packed) {
        return 'Dikemas';
      }

      if (this.item.is_valid) {
        return 'Dikirim ke Supplier';
      }

      if (this.item.is_prepared) {
        return 'Disiapkan Pusat';
      }

      return 'Belum Digunakan';
    },
  },
};
</script>
