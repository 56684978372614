<template>
  <base-wrapper :loading="loadingHistory || loadingJobStatus">
    <div class="grid grid-cols-1 gap-4">
      <base-card title="Generate QR Code">
        <form
          class="grid grid-cols-1 gap-4"
          @submit.prevent="confirming = true"
        >
          <base-input
            label="Jumlah"
            type="text"
            :shadow="false"
            fullwidth
            placeholder="Jumlah"
            v-model="form.qty"
          />
          <div class="col-span-full">
            <base-button
              :loading="loadingGenerate.visible"
              :disabled="!valid"
              >{{
                loadingGenerate.visible ? loadingGenerate.text : 'Generate'
              }}</base-button
            >
          </div>
          <alert v-if="success.visible" @dismiss="onDismissSuccess">
            Generate qr code berhasil, klik
            <a :href="success.url" target="_blank" class="font-bold underline"
              >download</a
            >
            untuk melihat file hasil generate.
          </alert>
        </form>
      </base-card>
      <base-card title="Daftar File QR Code">
        <datatable
          :columns="columns"
          :total="generateQrCodeHistories.meta.page.total"
          :perPage="generateQrCodeHistories.meta.page.perPage"
          :currentPage="generateQrCodeHistories.meta.page.currentPage"
          @pagechanged="onPagechanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="history in generateQrCodeHistories.data"
              :key="history.id"
              :class="[classes.tr, 'bg-white hover:bg-green-100']"
            >
              <td :class="[classes.td, 'font-bold text-gray-900 cursor-pointer']" @click="$router.push({ name: 'generate-qr-code.detail', params: { id: history.id } })">
                {{ history.attributes.start }} -
                {{ history.attributes.end }}
              </td>
              <td :class="[classes.td, 'cursor-pointer']" @click="$router.push({ name: 'generate-qr-code.detail', params: { id: history.id } })">
                {{ history.attributes.createdAt | formatDate }}
              </td>
              <td :class="[classes.td, 'cursor-pointer']" @click="$router.push({ name: 'generate-qr-code.detail', params: { id: history.id } })">
                {{ history.attributes.qty | toCurrency }}
              </td>
              <td :class="[classes.td]">
                <a
                  class="font-bold text-green-600"
                  target="_blank"
                  :href="history.attributes.file_url"
                  >Download</a
                >
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </div>
    <base-confirm
      message="Apakah anda yakin akan generate qr code?"
      :visible="confirming"
      icon-type="badge"
      type="warning"
      @close="confirming = false"
      @confirmed="onConfirmed"
    ></base-confirm>
  </base-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import Alert from '@/components/base/Alert.vue';

export default {
  mixins: [requestMixin],
  components: { BaseConfirm, Alert },
  data() {
    return {
      channelName: null,
      loadingGenerate: {
        visible: false,
        text: null,
      },
      loadingHistory: false,
      loadingJobStatus: false,
      confirming: false,
      form: {
        qty: null,
      },
      success: {
        visible: false,
        url: null,
      },
      generateQrCodeHistories: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    valid() {
      const qty = Number(this.form.qty);

      return qty > 0;
    },
    columns() {
      return [
        {
          id: 'code',
          name: 'No. Mulai - No. Akhir',
        },
        {
          id: 'date',
          name: 'Tanggal',
        },
        {
          id: 'qty',
          name: 'Jumlah',
        },
        {
          id: 'download',
          name: 'Download',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    addListener() {
      this.channelName = `private-App.Models.User.${this.me.id}`;

      const userChannel = this.$pusher.subscribe(this.channelName);

      userChannel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (e) => {
          if (e.type === 'App\\Notifications\\ProductQrCodeGenerated') {
            if (e.status === 'processing') {
              this.loadingGenerate.text = 'Sedang Diproses';
            } else if (e.status === 'failed') {
              this.stopLoadingGenerate();

              this.createAlert({
                data: 'Gagal menarik data',
                status: 'error',
              });
            } else if (e.status === 'finished') {
              this.stopLoadingGenerate();

              this.success.visible = true;
              this.success.url = e.file_url;

              this.loadHistory();
            }
          }
        }
      );
    },
    async loadHistory(params) {
      this.loadingHistory = true;

      const [res, error] = await this.request(
        '/api/v1/generate-qr-code-histories',
        {
          params: {
            'page[size]': 5,
            sort: '-createdAt',
            ...params,
          },
        }
      );

      if (!error) {
        this.generateQrCodeHistories = res;
      }

      this.loadingHistory = false;
    },
    async loadJobStatus() {
      this.loadingJobStatus = true;

      const [res, err] = await this.request(
        '/api/v1/users/-actions/check-job-status',
        {
          params: {
            type: 'generate_qr_code',
          },
        }
      );

      if (!err) {
        const { running, status } = res;

        if (running) {
          this.startLoadingGenerate(
            status === 'processing' ? 'Sedang Diproses' : status
          );
        }
      }

      this.loadingJobStatus = false;
    },
    onDismissSuccess() {
      this.success.visible = false;
    },
    async onConfirmed() {
      this.confirming = false;
      this.success.visible = false;

      this.startLoadingGenerate('Memproses Data');

      const [, error] = await this.request(
        '/api/v1/products/-actions/generate-qr-code',
        {
          method: 'post',
          data: {
            qty: this.form.qty,
          },
        }
      );

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error',
        });

        this.stopLoadingGenerate();
      }
    },
    onPagechanged(page) {
      this.loadHistory({
        'page[number]': page,
      });
    },
    removeListener() {
      this.$pusher.unsubscribe(this.channelName);
    },
    startLoadingGenerate(text) {
      this.loadingGenerate.text = text;
      this.loadingGenerate.visible = true;
    },
    stopLoadingGenerate() {
      this.loadingGenerate.visible = false;
    },
  },
  created() {
    this.loadHistory();
    this.loadJobStatus();
    this.addListener();
  },
  beforeRouteLeave(to, from, next) {
    this.removeListener();
    next();
  },
};
</script>
