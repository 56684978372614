<template>
  <div class="relative">
    <base-input
      inset
      type="text"
      label="Kode Barang"
      placeholder="Masukkan Kode Barang"
      :required="required"
      v-model="product.searchCode"
      @change="onSearch"
    />

    <button
      type="button"
      class="absolute right-4 top-6 cursor-pointer text-gray-400"
      @click="modalVisible = true"
    >
      <Icon class="h-5 w-5" icon="heroicons:magnifying-glass-circle-20-solid" />
    </button>

    <product-table-modal
      :visible="modalVisible"
      :params="params"
      @close="modalVisible = false"
      @click-product="onSelect"
    />

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ProductTableModal from './product-table-modal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    ProductTableModal,
  },
  props: {
    value: Object,
    required: Boolean,
    mode: {
      type: String,
      default: 'search',
    },
    params: Object
  },
  emits: ['input', 'searched', 'selected'],
  data() {
    return {
      loading: false,
      modalVisible: false,
    };
  },
  computed: {
    product: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert'
    }),
    async onSearch() {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/products', {
        params: {
          'page[size]': 1,
          'filter[code]': this.product.searchCode,
          'fields[products]': 'code,name',
          ...this.params
        },
      });

      if (err || !res.data.length) {
        if (this.mode === 'search') {
          this.product.searchCode = this.product.originalCode;

          this.createAlert({
            status: 'error',
            data: 'Produk tidak ditemukan'
          })
        } else {
          this.product.id = null;
          this.product.name = null;
          this.product.code = this.product.searchCode;
          this.product.originalCode = this.product.searchCode;
        }
      } else {
        const [product] = res.data;

        this.product.id = product.id;
        this.product.code = product.attributes.code;
        this.product.originalCode = product.attributes.code;
        this.product.name = product.attributes.name;
      }

      this.$emit('searched');

      this.loading = false;
    },
    onSelect(product) {
      this.product.id = product.id;
      this.product.code = product.attributes.code;
      this.product.originalCode = product.attributes.code;
      this.product.searchCode = product.attributes.code;
      this.product.name = product.attributes.name;

      this.$emit('selected');

      this.modalVisible = false;
    },
  },
};
</script>
