<template>
  <base-select
    :shadow="false"
    :options="options"
    v-model="selected"
    @change="$emit('change')"
  />
</template>

<script>
export default {
  props: {
    value: null,
    jsonapi: Boolean,
  },
  emits: ['input', 'change'],
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    options() {
      return [
        {
          key: 'null',
          label: 'Pilih Status',
          value: null,
        },
        {
          key: 'none',
          label: 'Belum Digunakan',
          value: this.jsonapi
            ? { 'filter[is_prepared]': false }
            : { is_prepared: false },
        },
        {
          key: 'is_prepared',
          label: 'Disiapkan',
          value: this.jsonapi
            ? { 'filter[is_prepared]': true, 'filter[is_valid]': false }
            : { is_prepared: true, is_valid: false },
        },
        {
          key: 'is_valid',
          label: 'Dikirim',
          value: this.jsonapi
            ? { 'filter[is_valid]': true, 'filter[is_packed]': false }
            : { is_valid: true, is_packed: false },
        },
        {
          key: 'is_packed',
          label: 'Dikemas',
          value: this.jsonapi
            ? { 'filter[is_packed]': true, 'filter[is_ordered]': false }
            : { is_packed: true, is_ordered: false },
        },
        {
          key: 'is_ordered',
          label: 'Dipesan',
          value: this.jsonapi
            ? { 'filter[is_ordered]': true, 'filter[is_scanned]': false }
            : { is_ordered: true, is_scanned: false },
        },
        {
          key: 'is_scanned',
          label: 'Diterima',
          value: this.jsonapi
            ? { 'filter[is_scanned]': true, 'filter[is_active]': false }
            : { is_scanned: true, is_active: false },
        },
        {
          key: 'is_active',
          label: 'Aktif',
          value: this.jsonapi
            ? { 'filter[is_active]': true, 'filter[is_used]': false }
            : { is_active: true, is_used: false },
        },
        {
          key: 'is_used',
          label: 'Digunakan',
          value: this.jsonapi ? { 'filter[is_used]': true } : { is_used: true },
        },
      ];
    },
  },
};
</script>
